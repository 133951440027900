import PropTypes from 'prop-types'
import Image from 'next/image'
import classNames from 'classnames'

// @ts-ignore
const ImageBox = ({ className, title, titleClassName, subTitle, image, imageClassName, width, height }) => {
  if (!image) return
  return (
    <div className={className}>
      {/* image */}
      <Image src={image} alt={title || ''} width={width || 500} height={height || 500} className={imageClassName} />

      {/* title */}
      {title && (
        <h4
          className={classNames(
            'pt-2 text-[0.625rem] font-medium leading-3 text-[#191414] lg:pt-3 lg:text-xl lg:leading-[1.875rem]',
            titleClassName,
          )}
        >
          {title}
        </h4>
      )}

      {/* subtitle */}
      {subTitle && (
        <p className="pt-0.5 text-[0.5rem] font-normal leading-[0.625rem] text-[#191414] lg:text-base lg:leading-[1.125rem]">
          {subTitle}
        </p>
      )}
    </div>
  )
}

ImageBox.propTypes = {
  className: PropTypes.string,
  titleClassName: PropTypes.string,
  imageClassName: PropTypes.string,
  image: PropTypes.string.isRequired,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
}

export default ImageBox
