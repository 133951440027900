import PropTypes from 'prop-types'
import classNames from 'classnames'
import Link from 'next/link'

// @shared
import { Button } from '@shared/components/ui/button'
import LandingPageSection from '@shared/components/LandingPageSection'
import ContentBox from '@shared/components/ContentBox'

// @ts-ignore
const JoinUsSection = ({ className, title, description }) => {
  return (
    <LandingPageSection className={classNames(className, '!w-full !max-w-full')}>
      <ContentBox
        className="pb-[1.813rem] lg:pb-6"
        mainTitle={title}
        mainTitleClassName="pb-6"
        description={description}
      >
        <JoinUsButton />
      </ContentBox>
    </LandingPageSection>
  )
}

JoinUsSection.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
}

const JoinUsButton = () => {
  return (
    <div className="text-center">
      <Button>
        <Link href="/auth/sign-up">
          <span>Join Cohart</span>
        </Link>
      </Button>
    </div>
  )
}

export default JoinUsSection
