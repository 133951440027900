// local components
import OurMissionSection from './OurMissionSection'
import LeadershipTeamSection from './LeadershipTeamSection'
import OurStrategicAdvisorsSection from './OurStrategicAdvisorsSection'
import JoinUsSection from './JoinUsSection'
import WhoCohartIsForSection from './WhoCohartIsForSection'
import WeBackedBySection from './WeBackedBySection'

type LeadershipPerson = {
  name: string
  avatar: string
}

type LeaderShipTeam = {
  title: string
  description: string
  persons: Array<LeadershipPerson>
}

type BackedByImage = {
  name: string
  src: string
  className: string
  mobileClassName: string
}

type Advisor = {
  name: string
  avatar: string
  jobTitle: string
}

type StrategicAdvisor = {
  title: string
  persons: Array<Advisor>
}

type JoinUs = {
  title: string
  description: string
}

type BackedBy = {
  title: string
  images: Array<BackedByImage>
}

export type AboutUsPageProps = {
  isLoggedIn: boolean
  leaderShipTeam: LeaderShipTeam
  strategicAdvisor: StrategicAdvisor
  joinUs: JoinUs
  backedBy: BackedBy
}

const AboutUsPage = ({ isLoggedIn = false, leaderShipTeam, strategicAdvisor, joinUs, backedBy }: AboutUsPageProps) => {
  return (
    <main className="about-page">
      {/* Our mission */}
      <OurMissionSection />

      {/* Who Cohart is for */}
      <WhoCohartIsForSection />

      {/* Leadership team */}
      <LeadershipTeamSection
        title={leaderShipTeam.title}
        description={leaderShipTeam.description}
        persons={leaderShipTeam.persons}
      />

      {/* Our Strategic Advisors */}
      <OurStrategicAdvisorsSection title={strategicAdvisor.title} persons={strategicAdvisor.persons} />

      {/* Join Us on Our Journey */}
      {!isLoggedIn && <JoinUsSection title={joinUs.title} description={joinUs.description} />}

      {/* We’re Backed By */}
      <WeBackedBySection title={backedBy.title} images={backedBy.images} />
    </main>
  )
}

export default AboutUsPage
