import PropTypes from 'prop-types'
import classNames from 'classnames'

// @shared
import LandingPageSection from '@shared/components/LandingPageSection'
import ContentBox from '@shared/components/ContentBox'
import ImageBox from '@shared/components/ImageBox'

// @ts-ignore
const OurStrategicAdvisorsSection = ({ className, title, persons }) => {
  return (
    <LandingPageSection className={classNames(className, '!w-full !max-w-full')}>
      <ContentBox className="pb-6 lg:pb-[2.313rem]" mainTitle={title}>
        {persons && <Persons persons={persons} />}
      </ContentBox>
    </LandingPageSection>
  )
}

OurStrategicAdvisorsSection.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  persons: PropTypes.array,
}

// @ts-ignore
const Persons = ({ className, persons }) => {
  if (!persons?.length) return
  return (
    <div className={classNames('flex flex-row flex-wrap', className)}>
      {persons.map((person: any, index: number) => (
        <ImageBox
          key={index}
          title={person.name}
          subTitle={person.jobTitle}
          image={person.avatar}
          width={600}
          height={600}
          className="w-1/3 px-2 pb-4 lg:w-1/6 lg:pb-0"
          imageClassName="h-[107px] lg:h-auto w-full object-cover rounded-lg lg:rounded-xl"
        />
      ))}
    </div>
  )
}

Persons.propTypes = {
  className: PropTypes.string,
  persons: PropTypes.array.isRequired,
}

export default OurStrategicAdvisorsSection
