import PropTypes from 'prop-types'
import classNames from 'classnames'

// @shared
import { Heading } from '@shared/components/Heading'
import { Text } from '@shared/components/Text'

// local
import ourMissionStyle from './index.module.scss'

// @ts-ignore
const OurMissionSection = ({ className }) => {
  return (
    <div className={classNames(ourMissionStyle.ourMissionSection, className)}>
      <div className="container pt-5 text-center lg:w-[70vw] lg:pt-24">
        {/* sub title */}
        <Text className="pb-2 text-sm font-normal uppercase leading-[1.138rem] tracking-[-1.4px] text-white lg:pb-5 lg:text-[1.75rem] lg:font-medium lg:leading-normal">
          Our mission
        </Text>

        {/* title */}
        <Heading
          size="h1"
          className="pb-[13.125rem] text-2xl font-medium leading-[1.875rem] tracking-[-0.72px] text-white lg:pb-[21.438rem] lg:text-7xl lg:font-semibold lg:leading-[100%]"
        >
          Experience art <br></br>
          powered by community
        </Heading>

        {/* content box */}
        <div className="flex flex-col gap-2 rounded-2xl bg-[#f0f0f0]/[.5] p-4 backdrop-blur-[2.875rem] lg:gap-2.5 lg:rounded-[2.5rem] lg:px-[2.813rem] lg:py-[2.688rem]">
          <Text size="2xl" className="text-base	font-normal leading-[1.125rem] text-[#767676] lg:text-3xl lg:leading-9">
            What We Do
          </Text>
          <Text size="2xl" className="text-sm font-normal leading-4 text-[#767676] lg:text-4xl lg:leading-10">
            Cohart provides digital tools that empower artists to manage direct sales and communities with credibility,
            while seamlessly connecting them with global opportunities to be discovered by galleries, advisors, and
            buyers.
          </Text>
        </div>
      </div>
    </div>
  )
}

OurMissionSection.propTypes = {
  className: PropTypes.string,
}

export default OurMissionSection
