import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Image from 'next/image'
import Link from 'next/link'

// @shared
import { Heading } from '@shared/components/Heading'
import { Card } from '@shared/components/ui/card'
import { Text } from '@shared/components/Text'
import LandingPageSection from '@shared/components/LandingPageSection'

// local
import whoCohartIsForStyle from './index.module.scss'

// constants
const ARTIST = 'artist'
const ADVISOR = 'advisors'
const FOR_KEY_NAME = { ARTIST, ADVISOR }

// @ts-ignore
const WhoCohartIsForSection = ({ className }) => {
  const [currentView, setCurrentView] = useState(ARTIST)
  const [isAnimating, setIsAnimating] = useState(false)

  //  automatically changed into one another after 3s
  useEffect(() => {
    const interval = setInterval(() => {
      toggleView()
    }, 3000)
    return () => clearInterval(interval)
  }, [currentView])

  const toggleView = () => {
    const nextView = currentView === ARTIST ? ADVISOR : ARTIST
    handleToggleView(nextView)
  }

  const handleToggleView = (view: any) => {
    if (currentView === view || isAnimating) return
    setIsAnimating(true)

    setTimeout(() => {
      setCurrentView(view)
      setIsAnimating(false)
    }, 500)
  }

  return (
    <div className={classNames(whoCohartIsForStyle.whoCohartIsForSection, className)}>
      <LandingPageSection>
        <Card className="rounded-xl px-3 py-4 shadow lg:relative lg:rounded-2xl lg:px-[1.875rem] lg:py-[2.5rem]">
          <Heading
            size="h2"
            className="pb-6 text-2xl font-medium leading-[1.875rem] lg:absolute lg:pb-0 lg:text-5xl lg:leading-[100%]"
          >
            Who Cohart is for
          </Heading>

          <div className="flex flex-col-reverse gap-2.5 lg:flex-row">
            {/* content */}
            <div className="flex w-full flex-col justify-end lg:w-1/2 lg:pr-24">
              <div className="flex flex-col gap-5 lg:gap-6">
                {/* artist */}
                <Link href="/for-artists">
                  <Heading
                    size="h2"
                    className={classNames(
                      'border-b text-base font-bold leading-5	lg:text-4xl lg:leading-10',
                      currentView === FOR_KEY_NAME.ARTIST ? 'border-black' : 'border-black/[.5] text-black/[.5]',
                    )}
                  >
                    For Artists
                  </Heading>
                  <Text
                    size="2xl"
                    className="text-xs font-normal leading-[1.125rem] text-black/[.5] lg:pt-3 lg:text-base"
                  >
                    Launch your Portfolio, grow your community, and get discovered for new opportunities.
                  </Text>
                </Link>

                {/* advisor */}
                <Link href="/for-advisors">
                  <Heading
                    size="h2"
                    className={classNames(
                      'border-b text-base font-bold leading-5	lg:text-4xl lg:leading-10',
                      currentView === FOR_KEY_NAME.ADVISOR ? 'border-black' : 'border-black/[.5] text-black/[.5]',
                    )}
                  >
                    For Advisors
                  </Heading>
                  <Text
                    size="2xl"
                    className="text-xs font-normal	leading-[1.125rem] text-black/[.5] lg:pt-3 lg:text-base"
                  >
                    Track talent, engage with artists, and manage your relationships—all in one place.
                  </Text>
                </Link>
              </div>
            </div>

            {/* image */}
            <div className="relative w-full pb-6 lg:w-1/2 lg:pb-0">
              <div className="relative h-[262px] overflow-hidden lg:h-[548px]">
                {/* Artist Image */}
                <Image
                  src="/images/landing-pages/about/who/artist.jpeg"
                  width={1500}
                  height={1400}
                  alt="For Artists"
                  className={classNames(
                    'absolute left-0 top-0 h-full w-full rounded-lg object-cover lg:rounded-xl',
                    currentView === ARTIST && !isAnimating ? 'animate-slideUp' : currentView !== ARTIST && 'hidden',
                  )}
                />
                {/* Advisor Image */}
                <Image
                  src="/images/landing-pages/about/who/advisor.jpeg"
                  width={1500}
                  height={1400}
                  alt="For Advisors"
                  className={classNames(
                    'absolute left-0 top-0 h-full w-full rounded-lg object-cover lg:rounded-xl',
                    currentView === ADVISOR && !isAnimating ? 'animate-slideDown' : currentView !== ADVISOR && 'hidden',
                  )}
                />
              </div>
            </div>
          </div>
        </Card>
      </LandingPageSection>
    </div>
  )
}

WhoCohartIsForSection.propTypes = {
  className: PropTypes.string,
}

export default WhoCohartIsForSection
