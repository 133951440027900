import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'
import cls from 'classnames'
import _ from 'lodash'
import { isServer } from '@tanstack/react-query'

// @shared
import LandingPageSection from '@shared/components/LandingPageSection'
import ContentBox from '@shared/components/ContentBox'
import InfiniteSwiper from '@shared/components/InfiniteSwiper'

// @ts-ignore
const WeBackedBySection = ({ className, title, images }) => {
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' })
  const imageChunks = useMemo(() => _.chunk(images, 2), [])

  return (
    <LandingPageSection className={cls(className, '!w-full !max-w-full !px-0')}>
      <ContentBox mainTitle={title || 'We’re Backed By'}>
        {(isDesktop || isServer) && (
          <InfiniteSwiper
            slideContainerClassName="![--gap:60px]"
            slideClassName="!h-[150px] !flex items-center"
            slides={images.map((image: any) => (
              <img className={cls('object-cover object-center', image.className)} src={image.src} alt={image.name} />
            ))}
          />
        )}

        {!isDesktop && !isServer && (
          <div className="container flex flex-col gap-1" suppressHydrationWarning>
            {Array.from({ length: 3 }).map((__, rowIndex) => (
              <div key={rowIndex} className="flex items-center justify-center gap-5">
                {imageChunks[rowIndex].map((image: any, index: number) => (
                  <div key={index} className="h-[100px] w-1/2">
                    <img
                      className={cls('m-auto object-cover object-center', image.mobileClassName)}
                      src={image.src}
                      alt={image.name}
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}
      </ContentBox>
    </LandingPageSection>
  )
}

WeBackedBySection.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  images: PropTypes.array,
}

export default WeBackedBySection
